import { render, staticRenderFns } from "./DashboardBSC.vue?vue&type=template&id=1a91689e&scoped=true&"
import script from "./DashboardBSC.vue?vue&type=script&lang=js&"
export * from "./DashboardBSC.vue?vue&type=script&lang=js&"
import style0 from "./DashboardBSC.vue?vue&type=style&index=0&id=1a91689e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a91689e",
  null
  
)

export default component.exports