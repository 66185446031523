<template>
  <div class="div-container">
    <!-- NEW -->
    <v-row class="pa-0 ma-0">
      <v-col cols="24">
        <div class="text-center mt-2" style="position: relative">
          <span style="font-weight: 600; font-size: 23px" class="tway-violet--text"
            >Panel General de Indicadores Agregados</span
          >
          <div style="position: absolute; top: 0px; right: 0px">
            <v-dialog v-model="dialog" persistent height="auto" width="100%">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isLoading"
                  class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                  outlined
                  disabled
                >
                  <v-icon>mdi-loading</v-icon>
                  Cargando...
                </v-btn>
                <v-btn
                  v-else
                  class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  Detalle de Proyectos
                </v-btn>
              </template>
              <v-card :class="'modalSystem'">
                <v-card>
                  <div class="div-container">
                    <h3 class="text-center">Detalle de Proyectos</h3>
                    <v-btn icon right @click="dialog = false" class="float-right">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-row class="mt-6 overflow-x-auto">
                      <div class="table-responsive" ref="table-projects" @scroll.prevent="scrollHorizontal($event)">
                        <table class="table-projects">
                          <thead>
                            <tr>
                              <th class="first-th" colspan="7">Indicadores de Proyectos</th>
                              <th class="first-th" colspan="19">Indicadores de Negocio</th>
                            </tr>
                            <tr>
                              <th class="first-th" colspan="1">ID</th>
                              <th class="first-th" colspan="2">Roadmap</th>
                              <th class="first-th" colspan="4">Presupuesto</th>
                              <th class="first-th" colspan="3">OKR</th>
                              <th class="first-th" colspan="2">Rentabilidad</th>
                              <th class="first-th" colspan="4">Agilidad</th>
                              <th class="first-th" colspan="2">Eficiencia</th>
                              <th class="first-th" colspan="2">Engagement</th>
                              <th class="first-th" colspan="1">Satisfacción</th>
                              <th class="first-th" colspan="2">Desempeño</th>
                              <th class="first-th" colspan="3">Productividad</th>
                            </tr>
                            <tr>
                              <th class="second-th" scope="col">Código</th>

                              <th class="second-th" scope="col">Avance Real</th>
                              <th class="second-th" scope="col">Desviación</th>

                              <th class="second-th" scope="col">Gasto Actual</th>
                              <th class="second-th" scope="col">Presupuesto</th>
                              <th class="second-th" scope="col">Desviación según planeado</th>
                              <th class="second-th" scope="col">Gasto Actual</th>

                              <th class="second-th" scope="col">Objetivos</th>
                              <th class="second-th" scope="col">Resultado</th>
                              <th class="second-th" scope="col">Iniciativas</th>

                              <th class="second-th" scope="col">Digitalización de Proceso</th>
                              <th class="second-th" scope="col">Optimización</th>

                              <th class="second-th" scope="col">Tiempo de Ciclo</th>
                              <th class="second-th" scope="col">Velocidad</th>
                              <th class="second-th" scope="col">Predictibilidad</th>
                              <th class="second-th" scope="col">Eficiencia</th>

                              <th class="second-th" scope="col">Reducción de Costos de Proceso</th>
                              <th class="second-th" scope="col">Tasa de Venta</th>

                              <th class="second-th" scope="col">Adopción Tecnologías</th>
                              <th class="second-th" scope="col">Tasa de Conversión</th>

                              <th class="second-th" scope="col">NPS</th>

                              <th class="second-th" scope="col">Rendimiento</th>
                              <th class="second-th" scope="col">Mejora en Habilidades Digitales</th>

                              <th class="second-th" scope="col">ROI</th>
                              <th class="second-th" scope="col">Escalabilidad de Servicio</th>
                              <th class="second-th" scope="col">Escalabilidad de Proceso</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(projectFile, i) in projectsFile" :key="i">
                              <td>
                                <a
                                  class="title-element roboto mr-2"
                                  @click="
                                    () => {
                                      $router.push({
                                        name: 'dashboard-pmo',
                                        params: {
                                          enterpriseId: projectFile.enterpriseId,
                                          projectId: projectFile.projectId,
                                        },
                                      });
                                    }
                                  "
                                  >{{ projectFile.projectCode }}</a
                                >
                              </td>

                              <!-- Roadmap -->
                              <!-- Avance Real -->
                              <td>
                                {{ projectFile.realPercentageProgress }}
                              </td>
                              <!-- Desviación -->
                              <td>
                                {{ projectFile.deviationPercentageProgress }}
                              </td>

                              <!-- Presupuesto -->
                              <!-- Gasto Actual -->
                              <td>
                                {{ projectFile.resumeBudget0 }}
                              </td>
                              <!-- Presupuesto -->
                              <td>
                                {{ projectFile.resumeBudget4 }}
                              </td>
                              <!-- Desviación según planeado -->
                              <td>
                                {{ projectFile.resumeBudget2 }}
                              </td>
                              <!-- Gasto Actual	-->
                              <td>
                                {{ projectFile.resumeBudget1 }}
                              </td>

                              <!-- OKR -->
                              <!-- Objetivos -->
                              <td>
                                <span>{{ projectFile.okrObjetivos }}</span>
                              </td>
                              <!-- Resultado -->
                              <td>
                                <span>{{ projectFile.okrObjetivos }}</span>
                              </td>
                              <!-- Iniciativas -->
                              <td>
                                <span>{{ projectFile.okrIniciativas }}</span>
                              </td>

                              <!-- Rentabilidad -->
                              <!-- Digitalización de Proceso -->
                              <td>
                                <span>{{ projectFile.profitProcess0 }}</span>
                              </td>
                              <!-- Optimización -->
                              <td>
                                <span>{{ projectFile.profitOpmization0 }}</span>
                              </td>

                              <!-- Agilidad -->
                              <!-- Tiempo de Ciclo -->
                              <td>
                                <span> {{ projectFile.agilitiDataTpoCiclo }}</span>
                              </td>
                              <!-- Velocidad -->
                              <td>
                                <span> {{ projectFile.agilitiDataVelocidad }}</span>
                              </td>
                              <!-- Predictibilidad -->
                              <td>
                                <span> {{ projectFile.agilitiDataPredictibilidad }}</span>
                              </td>
                              <!-- Eficiencia -->
                              <td>
                                <span> {{ projectFile.agilitiDataEficiencia }}</span>
                              </td>

                              <!-- Eficiencia -->
                              <!-- Reducción de Costos de Proceso -->
                              <td>
                                <span> {{ projectFile.dataEfficiencyAvgCost }}</span>
                              </td>
                              <!-- Tasa de Venta -->
                              <td>
                                <span> {{ projectFile.dataEfficiencyAvgSale }}</span>
                              </td>

                              <!-- Engagement -->
                              <!-- Adopción Tecnologías -->
                              <td>
                                <span>{{ projectFile.engTecAdop }}</span>
                              </td>
                              <!-- Tasa de Conversión -->
                              <td>
                                <span>{{ projectFile.engConvTec }}</span>
                              </td>

                              <!-- Satisfacción -->
                              <!-- NPS -->
                              <td>
                                <span>{{ projectFile.summaryNPS }}</span>
                              </td>

                              <!-- Desempeño -->
                              <!-- Rendimiento -->
                              <td>
                                <span>{{ projectFile.circularYield }}</span>
                              </td>
                              <!-- Mejora en Habilidades Digitales -->
                              <td>
                                <span> {{ projectFile.digitalSkillImprovement }}</span>
                              </td>

                              <!-- Productividad -->
                              <!-- ROI -->
                              <td>
                                <span>{{ projectFile.avgRoi }}</span>
                              </td>
                              <!-- Escalabilidad de Servicio -->
                              <td>
                                <span> {{ projectFile.scalabilityService }}</span>
                              </td>
                              <!-- Escalabilidad de Proceso -->
                              <td>
                                <span> {{ projectFile.scalabilityProcess }}</span>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr style="font-weight: 450; font-size: 1.1em">
                              <td><b>Total</b></td>

                              <!-- Roadmap -->
                              <!-- Avance Real -->
                              <td>{{ totalProjects.percentageProgress }}%</td>
                              <!-- Desviación -->
                              <td>{{ totalProjects.deviationPercentageProgressNumber }}%</td>

                              <!-- Presupuesto -->
                              <!-- Gasto Actual -->
                              <td>
                                {{ Number(totalProjects.resumeBudget0).toLocaleString() }}
                              </td>
                              <!-- Presupuesto -->
                              <td>
                                {{ Number(totalProjects.resumeBudget4).toLocaleString() }}
                              </td>
                              <!-- Desviación según planeado -->
                              <td>{{ totalProjects.resumeBudget2 }}%</td>
                              <!-- Gasto Actual	-->
                              <td>{{ totalProjects.resumeBudget1 }}%</td>

                              <!-- OKR -->
                              <!-- Objetivos -->
                              <td>{{ totalProjects.okrObjetivos }}%</td>
                              <!-- Resultado -->
                              <td>{{ totalProjects.okrResultados }}%</td>
                              <!-- Iniciativas -->
                              <td>{{ totalProjects.okrIniciativas }}%</td>

                              <!-- Rentabilidad -->
                              <!-- Digitalización de Proceso -->
                              <td>{{ totalProjects.profitProcess }}%</td>
                              <!-- Optimización -->
                              <td>{{ totalProjects.profitOpmization }}%</td>

                              <!-- Agilidad -->
                              <!-- Tiempo de Ciclo -->
                              <td>{{ totalProjects.agilityTpoCiclo }}%</td>
                              <!-- Velocidad -->
                              <td>{{ totalProjects.agilityVelocidad }}%</td>
                              <!-- Predictibilidad -->
                              <td>{{ totalProjects.agilityPredictibilidad }}%</td>
                              <!-- Eficiencia -->
                              <td>{{ totalProjects.agilityEficiencia }}%</td>

                              <!-- Eficiencia -->
                              <!-- Reducción de Costos de Proceso -->
                              <td>{{ totalProjects.dataEfficiencyAvgCost }}%</td>
                              <!-- Tasa de Venta -->
                              <td>{{ totalProjects.dataEfficiencySale }}%</td>

                              <!-- Engagement -->
                              <!-- Adopción Tecnologías -->
                              <td>{{ totalProjects.endDataEngTecAdop }}%</td>
                              <!-- Tasa de Conversión -->
                              <td>{{ totalProjects.endDataEngConvTec }}%</td>

                              <!-- Satisfacción -->
                              <!-- NPS -->
                              <td>{{ totalProjects.npsValue }}</td>

                              <!-- Desempeño -->
                              <!-- Rendimiento -->
                              <td>{{ totalProjects.performanceRendimiento }}%</td>
                              <!-- Mejora en Habilidades Digitales -->
                              <td>{{ totalProjects.performanceMejora }}%</td>

                              <!-- Productividad -->
                              <!-- ROI -->
                              <td>{{ totalProjects.avgRoi }}%</td>
                              <!-- Escalabilidad de Servicio -->
                              <td>{{ totalProjects.scalabilityService }}%</td>
                              <!-- Escalabilidad de Proceso -->
                              <td>{{ totalProjects.scalabilityProcess }}%</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </v-row>
                  </div>
                </v-card>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- ROEADMAP & BUDGET & RISK -->
    <v-row class="pa-0 ma-0">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span style="font-weight: 600; font-size: 21px" class="tway-violet--text">Indicadores de Proyecto</span>
      </v-col>
      <!-- Roadmap -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 text-title">
            <strong class="tway-violet--text">Roadmap</strong>
          </v-row>
          <v-row class="mt-8 box-content">
            <v-row class="pa-0 ma-0 box-container height-fix">
              <v-col class="pa-0 ma-0 inside-box">
                <div class="text-center">
                  <p style="font-size: 16px">
                    Avance Real
                    <ToolTips
                      width="250"
                      description="Promedio del Avance Real de todos los Proyectos  de Transformación Digital de la Empresa."
                    />
                  </p>
                </div>
                <div>
                  <VueApexCharts
                    v-if="chartSummary.series.length > 0"
                    :series="chartSummary.series"
                    :options="chartSummary.options"
                    height="220"
                    type="radialBar"
                  />
                  <div v-else class="sindatos-info">S/D</div>
                </div>
              </v-col>
              <v-col class="pa-0 ma-0 inside-box" align="center" justify-self="center">
                <div class="text-center fix-text">
                  <p style="font-size: 16px">
                    Desviación
                    <ToolTips
                      width="250"
                      description="Promedio de Desviaciones de todos los Proyectos de Transformación Digital de la Empresa."
                    />
                  </p>
                </div>
                <div class="text-veritical-align" v-if="chartSummary.series.length > 0">
                  <span
                    v-if="summaryPlanification.deviationPercentageProgressNumber < 0"
                    style="color: #d50000; font-size: 45px"
                    >{{ summaryPlanification.deviationPercentageProgress }}</span
                  >
                  <span
                    v-if="summaryPlanification.deviationPercentageProgressNumber >= 0"
                    style="color: #16c62e; font-size: 45px"
                    >{{ summaryPlanification.deviationPercentageProgress }}</span
                  >
                </div>
                <div v-else class="sindatos-info" style="margin-top: -20px">S/D</div>
              </v-col>
            </v-row>
          </v-row>
        </div>
      </v-col>
      <!-- Budget -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 text-title">
            <strong class="tway-violet--text">Presupuesto</strong>
          </v-row>
          <v-row class="mt-8 box-content">
            <v-row class="box-container">
              <v-col cols="12" class="pa-0 ma-0 inside-box">
                <div class="text-center">
                  <div class="text-center mt-2">
                    <p style="margin-bottom: 0px">
                      <span style="color: #333333; font-size: 20px">Gasto Actual: </span>
                      <span
                        v-if="budgetData.monetaryUnit != 'UF'"
                        class="mb-2"
                        style="color: #333333; font-size: 20px; font-weight: normal"
                        >{{ budgetData.monetaryUnit + " " + Number(budgetData.currentExpense).toLocaleString() }}</span
                      >
                      <span
                        v-if="budgetData.monetaryUnit == 'UF'"
                        class="mb-2"
                        style="color: #333333; font-size: 20px; font-weight: normal"
                        >{{ Number(budgetData.currentExpense).toLocaleString() + " " + budgetData.monetaryUnit }}</span
                      >
                      <span style="position: relative; top: -3px; left: 5px">
                        <ToolTips
                          width="250"
                          description="Suma de todos los Gastos incurridos a la fecha, en los Proyectos de Transformación Digital de la Empresa."
                        />
                      </span>
                    </p>
                  </div>
                  <div class="text-center">
                    <p style="margin-bottom: 0px">
                      <span style="color: #333333; font-size: 20px; font-weight: normal">Presupuesto Total: </span>
                      <span
                        v-if="budgetData.monetaryUnit != 'UF'"
                        class="mb-2"
                        style="color: #333333; font-size: 20px; font-weight: normal"
                        >{{ budgetData.monetaryUnit + " " + Number(budgetData.totalBudget).toLocaleString() }}</span
                      >
                      <span
                        v-if="budgetData.monetaryUnit == 'UF'"
                        class="mb-2"
                        style="color: #333333; font-size: 16px; font-weight: normal"
                        >{{ Number(budgetData.totalBudget).toLocaleString() + " " + budgetData.monetaryUnit }}</span
                      >
                      <span style="position: relative; top: -3px; left: 5px">
                        <ToolTips
                          width="250"
                          description="Suma del Presupuesto Total para todos los Proyectos de Transformación Digital de la Empresa."
                        />
                      </span>
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0 box-container height-fix">
              <v-col class="pa-0 ma-0 inside-box" align="center">
                <div>
                  <p style="color: #333333; font-size: 16px">
                    Desviación Según Planeado
                    <ToolTips
                      width="250"
                      description="Promedio de Desviaciones de Presupuesto de todos los Proyectos de Transformación Digital de la Empresa."
                    />
                  </p>
                </div>
                <div class="text-veritical-align">
                  <span
                    v-if="budgetData?.deviation"
                    style="color: #7319d5; font-size: 45px; font-weight: normal; margin-top: 50%"
                    >{{ budgetData.deviation }}%</span
                  >
                  <div v-else class="sindatos-info">S/D</div>
                </div>
              </v-col>
              <v-col class="pa-0 ma-0 inside-box" align="center" justify-self="center">
                <div class="tway-violet--text text-center">
                  <span style="color: #333333; font-size: 16px">
                    Gasto Actual
                    <ToolTips
                      width="250"
                      description="Promedio del porcentaje del Gasto total incurrido a la fecha, de todos los Proyectos de Transformación Digital de la Empresa."
                    />
                  </span>
                </div>
                <div class="mt-12">
                  <VueApexCharts
                    v-if="budgetData?.deviation"
                    :series="chartExpense.series"
                    :options="chartExpense.chartOptions"
                    height="190"
                    type="radialBar"
                  />
                  <div v-else class="sindatos-info">S/D</div>
                </div>
              </v-col>
            </v-row>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- OKR & PROFIT -->
    <v-row class="pa-0 ma-0">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span style="font-weight: 600; font-size: 23px" class="tway-violet--text"> Indicadores de Negocio</span>
      </v-col>
      <!-- Okr -->
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 mb-3" align="center" justify="space-between">
            <strong class="tway-violet--text">OKR</strong>
          </v-row>
          <v-row class="pa-0 ma-0 mt-3 box-container">
            <v-col class="pa-0 ma-0" cols="4">
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row; justify-content: center">
                  <p style="color: #333333; font-size: 16px">
                    {{ okrTitle.title1 }}
                    <ToolTips
                      width="400"
                      description="Objetivos Completados: Total de Objetivos de la Empresa que cumplen con todas las metas propuestas para sus Resultados Claves."
                      description2="Objetivos En Progreso: Total de Objetivos de la Empresa que cuentan con algunos de sus Resultados Claves cumplidos."
                      description3="Objetivos No Iniciados: Total de  Objetivos de la Empresa que no cuentan con las Metas de los Resultados Claves cumplidas."
                    />
                  </p>
                </div>
                <VueApexCharts
                  v-if="chartOkr1.total > 0"
                  ref="chartOkr1"
                  :series="chartOkr1.series"
                  :options="chartOkr1.options"
                  :labels="chartOkr1.options.labels"
                  height="280"
                  type="pie"
                />
                <div v-else class="sindatos-info">S/D</div>
              </div>
            </v-col>
            <v-col class="pa-0 ma-0" cols="4">
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row; justify-content: center">
                  <p style="color: #333333; font-size: 16px">
                    {{ okrTitle.title2 }}
                    <ToolTips
                      width="400"
                      description="Resultados Completados: Total de Resultados Claves de la Empresa, que cumplieron o superaron el 100% de sus metas propuestas."
                      description2="Resultados En Progreso: Total de Resultados Claves de la Empresa, que cuentan con avance en sus metas, pero aún no las alcanzan o superan."
                      description3="Resultados No Iniciados: Total de Resultados Claves de la Empresa, que aún no presentan avance en su progreso."
                    />
                  </p>
                </div>
                <VueApexCharts
                  v-if="chartOkr2.total > 0"
                  ref="chartOkr2"
                  :series="chartOkr2.series"
                  :options="chartOkr2.options"
                  :labels="chartOkr2.options.labels"
                  height="280"
                  type="pie"
                />
                <div v-else class="sindatos-info">S/D</div>
              </div>
            </v-col>
            <v-col class="pa-0 ma-0" cols="4">
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row; justify-content: center">
                  <p style="color: #333333; font-size: 16px">
                    {{ okrTitle.title3 }}
                    <ToolTips
                      width="400"
                      description="Iniciativas Completadas: Total de Iniciativas o Proyectos de Transformación de la Empresa, que han terminado su ejecución completamente."
                      description2="Iniciativas En Progreso: Total de Iniciativas o Proyectos de Transformación, que presentan avance en su progreso, y aún no cumplen con el hito de cierre."
                      description3="Iniciativas No Iniciadas: Total de Iniciativas o Proyectos de Transformación, que no han iniciado su ejecución, o no reflejan avance de su progreso."
                    />
                  </p>
                </div>
                <VueApexCharts
                  v-if="chartOkr3.total > 0"
                  ref="chartOkr3"
                  :series="chartOkr3.series"
                  :options="chartOkr3.options"
                  :labels="chartOkr3.options.labels"
                  height="280"
                  type="pie"
                />
                <div v-else class="sindatos-info">S/D</div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- Rentabilidad -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 mb-3" align="center" justify="space-between">
            <strong class="tway-violet--text">Rentabilidad</strong>
          </v-row>
          <v-row class="pa-0 ma-0" style="display: flex; flex-direction: column">
            <div class="text-center mt-3">
              <span style="color: #333333; font-size: 20px">Operaciones </span>
            </div>
            <v-col class="pa-0 ma-0 mt-6" cols="12">
              <div>
                <div class="text-center">
                  <p style="font-size: 16px">
                    Digitalización de Proceso
                    <ToolTips
                      width="300"
                      description="Promedio de Rentabilidad acumulada entre todos los Proyectos de Transformación Digital que lleva adelante la Empresa."
                    />
                  </p>
                </div>
                <VueApexCharts
                  v-if="chartDigitizationProcess.data"
                  :series="chartDigitizationProcess.series"
                  :options="chartDigitizationProcess.chartOptions"
                  height="190"
                  type="radialBar"
                />
                <div v-else class="sindatos-info">S/D</div>
              </div>
            </v-col>
            <v-col class="pa-0 ma-0 mt-8" cols="12" align="center" justify-self="center">
              <div>
                <div class="text-center">
                  <p style="font-size: 16px">
                    Optimización
                    <ToolTips
                      width="300"
                      description="Promedio de todos los Ratio de Ganancia ó Pérdida sobre lo invertido producto de mejoras en logística, atención a clientes, modernizaciones, etc. entre todos los Proyectos de Transformación Digital de la Empresa."
                    />
                  </p>
                </div>
                <VueApexCharts
                  v-if="chartOpimization.data"
                  :series="chartOpimization.series"
                  :options="chartOpimization.chartOptions"
                  height="190"
                  type="radialBar"
                />
                <div v-else class="sindatos-info">S/D</div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- AGILITY & EFFICIENCY -->
    <v-row class="pa-0 ma-0">
      <!-- Agility -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 text-title">
            <strong class="tway-violet--text">Agilidad</strong>
          </v-row>
          <v-row style="display: flex; flex-direction: row; align-content: space-between; height: 80%">
            <v-col class="pa-0 ma-0" align="center" justify-self="center">
              <div>
                <VueApexCharts
                  ref="chartAgility"
                  v-if="chartAgility.series.length > 0"
                  type="radar"
                  height="390"
                  :series="chartAgility.series"
                  :options="chartAgility.options"
                />
                <div v-else class="sindatos-info">S/D</div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- Efficiency -->
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 text-title">
            <strong class="tway-violet--text">Eficiencia</strong>
          </v-row>
          <v-row class="mt-7" style="display: flex; flex-direction: row; align-content: space-between">
            <v-col class="pa-0 ma-0" align="center" justify-self="center">
              <div class="text-center">
                <span style="color: #333333; font-size: 20px">Herramientas Digitales </span>
              </div>
              <div class="text-center mt-4">
                <p style="font-size: 16px">
                  Reducción de Costos en Procesos
                  <ToolTips
                    width="300"
                    description="Promedio de la disminución de costos de producción por el impacto de todos los Proyectos de Transformación Digital de la Empresa."
                  />
                </p>
              </div>
              <div>
                <VueApexCharts
                  v-if="chartCostReduction.series[0]?.data?.length > 0"
                  ref="chartCostReduction"
                  :series="chartCostReduction.series"
                  :options="chartCostReduction.options"
                  width="290"
                  height="300"
                  type="bar"
                />
                <div v-else class="sindatos-info">S/D</div>
              </div>
            </v-col>
            <v-col class="pa-0 ma-0" align="center" justify-self="center">
              <div>
                <div class="text-center">
                  <span style="color: #333333; font-size: 20px">Canales Digitales </span>
                </div>
                <div class="text-center mt-4">
                  <p style="font-size: 16px">
                    Tasa de Venta
                    <ToolTips
                      width="300"
                      description="Promedio de Usuarios que realiza compras online, producto de todos los Proyectos de Transformación Digital de la Empresa."
                    />
                  </p>
                </div>
                <VueApexCharts
                  v-if="chartDigitalSale.series[0]?.data.length > 0"
                  ref="chartDigitalSale"
                  :series="chartDigitalSale.series"
                  :options="chartDigitalSale.options"
                  width="290"
                  height="300"
                  type="area"
                />
                <div v-else class="sindatos-info">S/D</div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- ENGAGEMENT & KPI -->
    <v-row class="pa-0 ma-0">
      <!-- Engagement -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 mb-3" align="center" justify="space-between">
            <strong class="tway-violet--text">Engagement</strong>
          </v-row>
          <v-row class="pa-0 ma-0" style="display: flex; flex-direction: column">
            <!-- transformación digital -->
            <v-col class="pa-0 ma-0 mt-6" cols="12">
              <div>
                <div>
                  <div class="text-center">
                    <p style="font-size: 16px">
                      Adopción Tecnologías
                      <ToolTips
                        width="250"
                        description="Promedio de Adopción de Tecnologías, generado por todos los Proyectos de Transformación Digital de la Empresa."
                      />
                    </p>
                  </div>
                  <VueApexCharts
                    v-if="chartTechnologyAdoption.series.length > 0"
                    :series="chartTechnologyAdoption.series"
                    :options="chartTechnologyAdoption.options"
                    height="190"
                    type="radialBar"
                  />
                  <div v-else class="sindatos-info">S/D</div>
                </div>
              </div>
            </v-col>
            <v-col class="pa-0 ma-0 mt-8" cols="12" align="center" justify-self="center">
              <div>
                <div class="text-center">
                  <div>
                    <p style="font-size: 16px">
                      Tasa de Conversión
                      <ToolTips
                        width="250"
                        description="Promedio de la Tasa de Conversión lograda por todos los Proyectos de Transformación Digital de la Empresa."
                      />
                    </p>
                  </div>
                  <div>
                    <VueApexCharts
                      v-if="chartCircleConversionRate.series.length > 0"
                      :series="chartCircleConversionRate.series"
                      :options="chartCircleConversionRate.options"
                      height="190"
                      type="radialBar"
                    />
                    <div v-else class="sindatos-info">S/D</div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- Kpis -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 mb-3" align="center" justify="space-between">
            <strong class="tway-violet--text">Satisfacción</strong>
          </v-row>
          <v-row
            class="pa-0 ma-0 mt-7"
            style="display: flex; flex-direction: column; align-content: space-between; height: 90%; width: 100%"
          >
            <v-row class="pa-0 ma-0" style="width: 100%">
              <v-col class="pa-0 ma-0">
                <div style="display: flex; flex-direction: row; justify-content: center">
                  <span style="color: #333333; font-size: 22px"
                    >NPS
                    <ToolTips
                      width="250"
                      description="NPS Promedio según el aporte estimado de cada Proyecto de Transformación Digital impulsado por la Empresa."
                      formula="%Promotores - %Detractores"
                    />:
                  </span>
                  <p style="color: white">..</p>
                  <span v-if="npsValue == 0" style="color: #333333; font-size: 24px">{{ npsValue }} </span>
                  <span v-if="npsValue < 0" style="color: #d50000; font-size: 24px">{{ npsValue }} </span>
                  <span v-if="npsValue > 0" style="color: #16c62e; font-size: 24px">{{ npsValue }} </span>
                  <img
                    v-if="npsValue > 0"
                    class="mt-1"
                    width="20px"
                    height="20px"
                    :src="require('@/assets/img/pmo/up-arrow-green.svg')"
                  />
                  <img
                    v-if="npsValue < 0"
                    class="mt-1"
                    width="20px"
                    height="20px"
                    :src="require('@/assets/img/pmo/down-arrow-red.svg')"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0 mt-3" style="width: 100%">
              <v-col class="pa-0 ma-0" align="center" justify-self="center">
                <p style="font-size: 16px">
                  Tasa de Resolución
                  <ToolTips
                    width="400"
                    description="Promedio de las Métricas de los distintos Proyectos de Transformación Digital de la Empresa."
                    description2="N1: servicio de primer contacto con el cliente."
                    description3="N2: primer nivel de escalamiento para la resolución de incidencias."
                    description4="N3: segundo nivel de escalamiento hacia especialistas para resolución de incidencias."
                  />
                </p>
                <div class="text-center">
                  <VueApexCharts
                    v-if="firstLine.series.length > 0"
                    :series="firstLine.series"
                    :options="firstLine.options"
                    height="350"
                    type="pie"
                  />
                  <div v-else class="sindatos-info">S/D</div>
                </div>
              </v-col>
            </v-row>
          </v-row>
        </div>
      </v-col>
      <!-- Performance -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 mb-3" align="center" justify="space-between">
            <strong class="tway-violet--text">Desempeño</strong>
          </v-row>
          <v-row class="pa-0 ma-0" style="display: flex; flex-direction: column">
            <div class="text-center mt-4">
              <span style="color: #333333; font-size: 20px">Talento Digital </span>
            </div>
            <v-col class="pa-0 ma-0 mt-6" cols="12">
              <div>
                <div class="text-center">
                  <p style="font-size: 16px">
                    Rendimiento
                    <ToolTips
                      width="400"
                      description="Promedio del Rendimiento del desempeño del Talento Digital, aportado por cada Proyecto de Transformación Digital de la Empresa."
                    />
                  </p>
                </div>
                <VueApexCharts
                  v-if="chartYield.series.length > 0"
                  :series="chartYield.series"
                  :options="chartYield.chartOptions"
                  height="190"
                  type="radialBar"
                />
                <div v-else class="sindatos-info">S/D</div>
              </div>
            </v-col>
            <v-col class="pa-0 ma-0 mt-8" cols="12" align="center" justify-self="center">
              <div>
                <div class="text-center">
                  <p style="font-size: 16px">
                    Mejora en Habilidades Digitales
                    <ToolTips
                      width="370"
                      description="Aumento en el nivel de dominio de habilidades digitales de trabajadores en una empresa."
                    />
                  </p>
                </div>
                <VueApexCharts
                  v-if="chartDigitalSkillsImprovement.series.length > 0"
                  :series="chartDigitalSkillsImprovement.series"
                  :options="chartDigitalSkillsImprovement.chartOptions"
                  height="190"
                  type="radialBar"
                />
                <div v-else class="sindatos-info">S/D</div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- PERFORMANCE & PRODUCTIVITY -->
    <v-row class="pa-0 ma-0">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <div class="fill-height pa-5 grid-container">
          <v-row class="pa-0 ma-0 text-title">
            <strong class="tway-violet--text">Productividad</strong>
          </v-row>
          <v-row class="pa-0 ma-0 mt-7 box-container">
            <v-col class="pa-0 ma-0">
              <div style="display: flex; flex-direction: column" align="center" justify-self="center">
                <div class="text-center">
                  <p style="font-size: 16px">
                    Retorno de la Inversión
                    <ToolTips
                      width="370"
                      description="Beneficio final que recibirá una empresa como resultado de invertir cierta cantidad de recursos en proyectos orientados a aumentar la productividad."
                    />
                  </p>
                </div>
                <div>
                  <VueApexCharts
                    v-if="chartInvestment.series.length > 0"
                    ref="chartInvestment"
                    :series="chartInvestment.series"
                    :options="chartInvestment.options"
                    width="400"
                    height="300"
                    type="area"
                  />
                  <div v-else class="sindatos-info">S/D</div>
                </div>
              </div>
            </v-col>
            <v-col
              cols="6"
              class="pa-0 ma-0"
              style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: start"
            >
              <div class="pa-0 ma-0" style="width: 100%; height: fit-content">
                <h3 class="text-center" style="font-size: 20px; font-weight: normal">Escalabilidad</h3>
              </div>

              <div class="pa-0 ma-0" style="width: 50%">
                <div style="display: flex; flex-direction: column">
                  <div class="text-center">
                    <p style="font-size: 16px">
                      Servicio
                      <ToolTips
                        width="370"
                        description="Capacidad deseable de un sistema o una red para reaccionar, adaptarse o crecer, sin perder calidad en los servicios ofrecidos."
                      />
                    </p>
                  </div>
                  <div>
                    <VueApexCharts
                      v-if="chartService.series.length > 0"
                      :series="chartService.series"
                      :options="chartService.chartOptions"
                      height="220"
                      type="radialBar"
                    />
                    <div v-else class="sindatos-info">S/D</div>
                  </div>
                </div>
              </div>
              <div class="pa-0 ma-0" style="width: 50%">
                <div style="display: flex; flex-direction: column">
                  <div>
                    <div class="text-center">
                      <p style="font-size: 16px">
                        Proceso
                        <ToolTips
                          width="370"
                          description="Habilidad deseable de un proceso para reaccionar, adaptarse o crecer en magnitud, sin perder eficiencia y productividad."
                        />
                      </p>
                    </div>
                    <VueApexCharts
                      v-if="chartProcess.series.length > 0"
                      :series="chartProcess.series"
                      :options="chartProcess.chartOptions"
                      height="220"
                      type="radialBar"
                    />
                    <div v-else class="sindatos-info">S/D</div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ToolTips from "../../components/pmo/utils/ToolTips";
import projectService from "../../services/pmo/project/projectService";
import PlanificationService from "../../services/pmo/planification/planificationService";
import BudgetService from "@/services/pmo/budget/budgetService";
import RiskService from "@/services/pmo/risk/riskService";
import ObjectivesService from "@/services/pmo/objectives/objectivesService";
import EfficiencyService from "../../services/pmo/efficiency/efficiencyService";
import EngagementService from "../../services/pmo/engagement/engagementService";
import KpiBusinessService from "@/services/pmo/kpi/kpiBusinessService";
import productivityService from "../../services/pmo/productivity/productivityService";
import PerformanceService from "@/services/pmo/performance/performanceService";
import AgilityService from "../../services/pmo/agility/organizationalAgilityService";
import ProfitabilityService from "../../services/pmo/profitability/profitabilityService";
import VueApexCharts from "vue-apexcharts";
//import PolarArea from "../../components/charts/pmo/apex/polar-area/PolarArea";

export default {
  name: "DashboardBSC",
  components: {
    VueApexCharts,
    ToolTips,
    //PolarArea,
  },
  data() {
    return {
      full: 100,
      projects: [
        {
          id: 1,
          name: "Canal de Ventas Online",
          priority: "High",
          status: "In process",
          complete: "25%",
        },
        {
          id: 2,
          name: "Rediseño Servicio Clientes",
          priority: "Normal",
          status: "In process",
          complete: "25%",
        },
        {
          id: 3,
          name: "Implentación Safe",
          priority: "High",
          status: "In process",
          complete: "35%",
        },
        {
          id: 4,
          name: "Model Engagment",
          priority: "Low",
          status: "Not Started",
          complete: "0%",
        },
      ],
      enterpriseId: {
        enterpriseId: "1",
      },
      dialog: false,
      // Datos generales del proyecto
      projectsFile: [],
      totalProjects: {},
      isLoading: true,
      //ROADMAP
      summaryPlanification: [],
      chartSummary: {
        series: [],
        options: {
          chart: {
            height: 350,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            colors: ["#ffffff"],
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              colors: ["#ffffff"],
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24
                }*/
              },
              track: {
                background: "#fff",
                colors: ["#ffffff"],
                strokeWidth: "67%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },
              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "30px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            colors: ["#7319d5"],
            type: "solid",
          },
          stroke: { lineCap: "round" },
          labels: [""],
        },
      },
      chartTrace: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 650,
            width: "100%",
            type: "rangeBar",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "100%",
            },
          },
          xaxis: {
            type: "datetime",
          },
          yaxis: {
            labels: {
              show: false,
              align: "left",
              minWidth: 0,
              maxWidth: 160,
              style: {
                colors: [],
                fontSize: "12px",
                fontWeight: 400,
                cssClass: "apexcharts-yaxis-label",
              },
              offsetX: 100,
              offsetY: 30,
              rotate: 0,
              formatter: function (val) {
                return val;
              },
            },
          },
          stroke: {
            width: 0.5,
          },
          fill: {
            type: "solid",
            opacity: 0.6,
          },

          legend: {
            position: "bottom",
            horizontalAlign: "center",
          },
        },
      },
      //PRESUPUESTO
      budgetData: {
        currentExpense: "",
        totalBudget: "",
        deviation: "",
        monetaryUnit: "",
      },
      chartExpense: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              colors: ["#ffffff"],
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24
                }*/
              },
              track: {
                background: "#fff",
                colors: ["#ffffff"],
                strokeWidth: "67%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },
              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "30px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7319d5"],
          },
          labels: [""],
          stroke: { lineCap: "round" },
        },
      },
      //RIESGO
      riskData: [],
      riskByDimension: {
        series: [],
        options: {
          labels: [],
          legend: {
            position: "bottom",
          },
          chart: {
            height: 400,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "polarArea",
            zoom: { enabled: false },
            offsetY: 0,
            offsetX: 0,
          },
          stroke: {
            colors: ["#fff"],
          },
          fill: {
            opacity: 0.8,
          },
          yaxis: {
            max: 0,
          },
          // Violeta Claro:'#7319D5' - Violeta Oscuro: '#4A148C' - Verde Oscuro: '#AEEA00' - Verde Claro:'#16C62E' - Gris: '#7F7F7F'
          colors: ["#7F7F7F", "#4A148C", "#16C62E", "#7319D5", "#AEEA00"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      riskResult: "",
      riskDimension: "",
      //OKR
      date: {
        start: "",
        end: "",
      },
      okrTitle: {
        title1: "",
        title2: "",
        title3: "",
      },
      chartOkr1: {
        series: [],
        total: 0,
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            width: 380,
            type: "pie",
            toolbar: {
              show: false,
            },
            offsetX: 0,
            offsetY: -20,
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5,
              },
            },
          },
          title: {
            text: "",
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "ligth",
              fontFamily: undefined,
              color: "#263238",
            },
          },
          colors: ["#16C62E", "#7F7F7F", "#7319d5"],
          labels: [],
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontSize: "11px",
            offsetX: 0,
            offsetY: 0,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 350,
                },
                legend: {
                  position: "right",
                  horizontalAlign: "left",
                  width: 50,
                  offsetX: 0,
                  offsetY: 20,
                },
              },
            },
          ],
          stroke: { lineCap: "round" },
        },
      },
      chartOkr2: {
        series: [],
        total: 0,
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            width: 380,
            type: "pie",
            toolbar: {
              show: false,
            },
            offsetX: 0,
            offsetY: -20,
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5,
              },
            },
          },
          title: {
            text: "",
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "ligth",
              fontFamily: undefined,
              color: "#263238",
            },
          },
          colors: ["#16C62E", "#7F7F7F", "#7319d5"],
          labels: [],
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontSize: "11px",
            offsetX: 0,
            offsetY: 0,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 350,
                },
                legend: {
                  position: "right",
                  horizontalAlign: "left",
                  width: 50,
                  offsetX: 0,
                  offsetY: 20,
                },
              },
            },
          ],
          stroke: { lineCap: "round" },
        },
      },
      chartOkr3: {
        series: [],
        total: 0,
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            width: 380,
            type: "pie",
            toolbar: {
              show: false,
            },
            offsetX: 0,
            offsetY: -20,
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5,
              },
            },
          },
          colors: ["#16C62E", "#7F7F7F", "#7319d5"],
          labels: [],
          xaxis: {
            offsetX: 100,
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontSize: "11px",
            offsetX: 0,
            offsetY: 0,
          },
          title: {
            text: "",
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "ligthter",
              fontFamily: "Josefin Sans, Arial, sans-serif",
              color: "#263238",
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 350,
                },
                legend: {
                  position: "right",
                  horizontalAlign: "left",
                  width: 50,
                  offsetX: 0,
                  offsetY: 20,
                },
              },
            },
          ],
          stroke: { lineCap: "round" },
        },
      },
      //AGILIDAD
      chartAgility: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radar",
            height: 350,
            toolbar: {
              show: false,
            },
            dropShadow: {
              enabled: true,
              blur: 1,
              left: 1,
              top: 1,
            },
          },
          responsive: [
            {
              breakpoint: 680,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: 0,
                  offsetY: 0,
                },
              },
            },
          ],
          colors: ["#7F7F7F", "#4A148C", "#16C62E", "#7319D5", "#AEEA00"],
          xaxis: {
            categories: ["Tiempo de Ciclo", "Velocidad", "Predictibilidad", "Eficiencia"],
            labels: {
              show: true,
              style: {
                colors: ["#7319D5", "#7319D5", "#7319D5", "#7319D5"],
                fontSize: 13,
              },
            },
          },
          stroke: {
            width: 2,
          },
          fill: {
            opacity: 0.1,
          },
          markers: {
            size: 0,
          },
          legend: {
            position: "bottom",
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
      //SATISFACCION
      npsValue: 0,
      firstLine: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "pie",
            height: 150,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 10,
            },
          },
          title: {
            text: undefined,
            align: "center",
            margin: 0,
            style: {
              fontSize: 16,
              fontWeight: "normal",
            },
          },
          xaxis: {
            categories: [""],
            min: 0,
            max: 100,
          },
          yaxis: [{}],
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
            x: {
              formatter: function () {
                return "Último mes registrado";
              },
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            offsetX: 0,
            offsetY: 0,
            itemMargin: {
              horizontal: 5,
              vertical: 4,
            },
          },
          dataLabels: {
            style: {
              fontSize: "14px",
              colors: ["#ffffff", "#ffffff", "#ffffff", "#404040", "#404040"],
            },
            dropShadow: {
              enabled: false,
            },
            formatter: function (val) {
              return val.toFixed(1);
            },
          },
          colors: ["#7319D5", "#8F47DD", "#AB75E5", "#C7A3EE", "#E3D1F6"],
        },
      },
      //ENGAGEMENT
      chartAdherenceMethodologies: {
        series: [],
        options: {
          series: [],
          chart: {
            offsetY: 0,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "radialBar",
            colors: ["#ffffff"],
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              colors: ["#ffffff"],
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24
                }*/
              },
              track: {
                background: "#fff",
                colors: ["#ffffff"],
                strokeWidth: "67%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "30px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319d5"],
            type: "solid",
          },
          stroke: { lineCap: "round" },
          labels: [""],
        },
      },
      chartTechnologyAdoption: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "55%",
              },
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319d5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartCircleConversionRate: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "55%",
              },
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319d5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartDigitalClient: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 450,
            offsetX: -20,
            type: "heatmap",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#7F7F7F", "#7319d5"].reverse(),
          xaxis: {
            type: "category",
            name: "Hola",
            categories: [],
          },
          title: {
            text: undefined,
          },
          grid: {
            padding: {
              right: 20,
            },
          },
        },
      },
      ltvSum: {
        thisMonthLTV: 0,
        lastMonthLTV: 0,
      },
      //DESEMPEÑO
      chartYield: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "55%",
              },
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7319d5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartDigitalSkillsImprovement: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "55%",
              },
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319d5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      //RENTABILIDAD
      chartDigitizationProcess: {
        series: [],
        data: true,
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "55%",
              },
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7319d5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartOpimization: {
        series: [],
        data: true,
        chartOptions: {
          chart: {
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
            fontFamily: "Josefin Sans, Arial, sans-serif",
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "55%",
              },
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "30px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7319d5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      //EFICIENCIA
      chartCostReduction: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 6,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7319D5"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: { style: { colors: "#7319D5" } },
              title: {
                text: "",
                style: { color: "#7319D5" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartDigitalSale: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7F7F7F"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7F7F7F",
              },
              labels: { style: { colors: "#7F7F7F" } },
              title: {
                text: "Cantidad de Usuarios",
                style: { color: "#7F7F7F" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      //PRODUCTIVIDAD
      chartInvestment: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7F7F7F"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7F7F7F",
              },
              labels: { style: { colors: "#7F7F7F" } },
              title: {
                text: "%",
                style: { color: "#7F7F7F" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartService: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                      }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "67%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "30px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319d5"],
            type: "solid",
          },
          labels: [""],
          stroke: { lineCap: "round" },
        },
      },
      chartProcess: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                      }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "67%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "30px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319d5"],
            type: "solid",
          },
          labels: [""],
          stroke: { lineCap: "round" },
        },
      },
      productivityData: {
        productivityServices: "",
        productivityProcess: "",
      },
    };
  },
  methods: {
    goPage() {},
    getData() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let clientId = "1";
      if (userInfo && userInfo.principal && userInfo.principal.clientId) {
        clientId = userInfo.principal.clientId;
      }
      this.enterpriseId.enterpriseId = clientId;

      this.getSummary();
      this.getProductivityData();
      this.getBudgetData();
      this.getKpisData();
      this.getOkrData();
      this.getEngagementData();
      this.getPerformanceData();
      this.getRiskData();
      this.getProfitability();
      this.getEfficiencyData();
      this.getAgilityData();
      this.getProjects();
    },
    getTotalResumeProjects(projects) {
      let _projects = [];
      let total = {
        percentageProgress: 0,
        deviationPercentageProgressNumber: 0,
        resumeBudget0: 0,
        resumeBudget1: 0,
        resumeBudget2: 0,
        resumeBudget3: 0,
        resumeBudget4: 0,
        okrObjetivos: 0,
        okrResultados: 0,
        okrIniciativas: 0,
        profitProcess: 0,
        profitOpmization: 0,
        agilityTpoCiclo: 0,
        agilityVelocidad: 0,
        agilityPredictibilidad: 0,
        agilityEficiencia: 0,
        dataEfficiencyAvgCost: 0,
        dataEfficiencySale: 0,
        endDataEngTecAdop: 0,
        endDataEngConvTec: 0,
        npsValue: 0,
        performanceRendimiento: 0,
        performanceMejora: 0,
        avgRoi: 0,
        scalabilityService: 0,
        scalabilityProcess: 0,
      };
      let totalProjects = 0;
      const NI = "NI";
      const SD = "S/D";
      projects.map((p) => {
        let _project = {
          ...p,
          realPercentageProgress: NI,
          deviationPercentageProgress: NI,
          resumeBudget0: NI,
          resumeBudget1: NI,
          resumeBudget2: NI,
          resumeBudget3: NI,
          resumeBudget4: NI,
          okrObjetivos: NI,
          okrResultados: NI,
          okrIniciativas: NI,
          profitProcess0: NI,
          profitOpmization0: NI,
          agilitiDataTpoCiclo: NI,
          agilitiDataVelocidad: NI,
          agilitiDataPredictibilidad: NI,
          agilitiDataEficiencia: NI,
          dataEfficiencyAvgCost: NI,
          dataEfficiencyAvgSale: NI,
          engTecAdop: NI,
          engConvTec: NI,
          summaryNPS: NI,
          circularYield: NI,
          digitalSkillImprovement: NI,
          avgRoi: NI,
          scalabilityService: NI,
          scalabilityProcess: NI,
        };

        total.resumeBudget0 += p.resumeBudget[0].value.replace(".", "") * 1;
        total.resumeBudget1 += p.resumeBudget[1].percentage.replace("%", "") * 1;
        total.resumeBudget2 += p.resumeBudget[2].percentage.replace("%", "") * 1;
        total.resumeBudget3 += p.resumeBudget[3].percentage.replace("%", "") * 1;
        total.resumeBudget4 += p.resumeBudget[4].value.replace(".", "") * 1;

        total.okrObjetivos += p.mapOKR.objetivos * 1;
        total.okrResultados += p.mapOKR.resultados * 1;
        total.okrIniciativas += p.mapOKR.iniciativas * 1;

        total.profitProcess += p.profit.process.data[0];
        total.profitOpmization += p.profit.opmization.data[0];

        total.agilityTpoCiclo += p.agilitiData.tpoCiclo;
        total.agilityVelocidad += p.agilitiData.velocidad;
        total.agilityPredictibilidad += p.agilitiData.predictibilidad;
        total.agilityEficiencia += p.agilitiData.eficiencia;

        total.dataEfficiencyAvgCost += p.dataEfficiency.avgCost;
        total.dataEfficiencySale += p.dataEfficiency.avgSale;

        if (p.endData?.engTecAdop) {
          total.endDataEngTecAdop += p.endData.engTecAdop;
        }
        if (p.endData?.endDataEngConvTec) {
          total.endDataEngConvTec += p.endData.engConvTec;
        }
        if (p.summaryNPS?.value) {
          total.npsValue += p.summaryNPS.value * 1;
        }

        total.performanceRendimiento += p.circularYield.series[0];
        total.performanceMejora += p.digitalSkillImprovement.series[0];

        total.avgRoi += p.avgRoi;

        total.scalabilityService += p.scalability.service.series[0][0];
        total.scalabilityProcess += p.scalability.process.series[0][0];

        if (p.dataPlanification) {
          total.percentageProgress += p.dataPlanification.percentageProgress * 1;
          total.deviationPercentageProgressNumber += p.dataPlanification.deviationPercentageProgressNumber * 1;
          if (p.dataPlanification.globalRealStartDateSummary) {
            totalProjects++;

            _project.realPercentageProgress = p.dataPlanification.realPercentageProgress;
            _project.deviationPercentageProgress = p.dataPlanification.deviationPercentageProgress;
            _project.resumeBudget0 = p.resumeBudget[0]?.value;
            _project.resumeBudget1 = p.resumeBudget[1]?.percentage;
            _project.resumeBudget2 = p.resumeBudget[2]?.percentage;
            _project.resumeBudget3 = p.resumeBudget[3]?.value;
            _project.resumeBudget4 = p.resumeBudget[4]?.value;
            _project.okrObjetivos = p.mapOKR?.objetivos + "%";
            _project.okrResultados = p.mapOKR?.resultados + "%";
            _project.okrIniciativas = p.mapOKR?.iniciativas + "%";
            _project.profitProcess0 = p.profit?.process?.data[0] + "%";
            _project.profitOpmization0 = p.profit?.opmization?.data[0] + "%";
            _project.agilitiDataTpoCiclo = p.agilitiData?.tpoCiclo + "%";
            _project.agilitiDataVelocidad = p.agilitiData?.velocidad + "%";
            _project.agilitiDataPredictibilidad = p.agilitiData?.predictibilidad + "%";
            _project.agilitiDataEficiencia = p.agilitiData?.eficiencia + "%";
            _project.dataEfficiencyAvgCost = p.dataEfficiency?.avgCost + "%";
            _project.dataEfficiencyAvgSale = p.dataEfficiency?.avgSale + "%";
            if (p.endData?.engTecAdop) {
              _project.engTecAdop = p.endData?.engTecAdop + "%";
            } else {
              _project.engTecAdop = SD;
            }
            if (p.endData?.engConvTec) {
              _project.engConvTec = p.endData?.engConvTec + "%";
            } else {
              _project.engConvTec = SD;
            }
            if (p.summaryNPS?.value) {
              _project.summaryNPS = p.summaryNPS?.value;
            } else {
              _project.summaryNPS = SD;
            }
            if (p.circularYield?.series[0]) {
              _project.circularYield = p.circularYield.series[0] + "%";
            } else {
              _project.circularYield = SD;
            }
            if (p.digitalSkillImprovement?.series[0]) {
              _project.digitalSkillImprovement = p.digitalSkillImprovement?.series[0] + "%";
            } else {
              _project.digitalSkillImprovement = SD;
            }
            _project.avgRoi = p.avgRoi + "%";
            if (p.scalability?.service?.series[0][0]) {
              _project.scalabilityService = p.scalability.service.series[0][0] + "%";
            } else {
              _project.scalabilityService = SD;
            }
            if (p.scalability?.process?.series[0][0]) {
              _project.scalabilityProcess = p.scalability.process.series[0][0] + "%";
            } else {
              _project.scalabilityProcess = SD;
            }
          }
        }

        _projects.push(_project);
      });

      total.percentageProgress = (total.percentageProgress / totalProjects).toFixed(1);
      total.deviationPercentageProgressNumber = (total.deviationPercentageProgressNumber / totalProjects).toFixed(1);

      total.resumeBudget1 = (total.resumeBudget1 / totalProjects).toFixed(1);
      total.resumeBudget2 = (total.resumeBudget2 / totalProjects).toFixed(1);
      total.resumeBudget3 = (total.resumeBudget3 / totalProjects).toFixed(1);

      total.okrObjetivos = (total.okrObjetivos / totalProjects).toFixed(1);
      total.okrResultados = (total.okrResultados / totalProjects).toFixed(1);
      total.okrIniciativas = (total.okrIniciativas / totalProjects).toFixed(1);

      total.profitProcess = (total.profitProcess / totalProjects).toFixed(1);
      total.profitOpmization = (total.profitOpmization / totalProjects).toFixed(1);

      total.agilityTpoCiclo = (total.agilityTpoCiclo / totalProjects).toFixed(1);
      total.agilityVelocidad = (total.agilityVelocidad / totalProjects).toFixed(1);
      total.agilityPredictibilidad = (total.agilityPredictibilidad / totalProjects).toFixed(1);
      total.agilityEficiencia = (total.agilityEficiencia / totalProjects).toFixed(1);

      total.dataEfficiencyAvgCost = (total.dataEfficiencyAvgCost / totalProjects).toFixed(1);
      total.dataEfficiencySale = (total.dataEfficiencySale / totalProjects).toFixed(1);

      total.endDataEngTecAdop = (total.endDataEngTecAdop / totalProjects).toFixed(1);
      total.endDataEngConvTec = (total.endDataEngConvTec / totalProjects).toFixed(1);

      total.npsValue = (total.npsValue / totalProjects).toFixed(1);

      total.performanceRendimiento = (total.performanceRendimiento / totalProjects).toFixed(1);
      total.performanceMejora = (total.performanceMejora / totalProjects).toFixed(1);

      total.avgRoi = (total.avgRoi / totalProjects).toFixed(1);

      total.scalabilityService = (total.scalabilityService / totalProjects).toFixed(1);
      total.scalabilityProcess = (total.scalabilityProcess / totalProjects).toFixed(1);
      return { total, projects: _projects };
    },
    getProjects() {
      projectService
        .getProjects(this.enterpriseId)
        .then((data) => {
          const formattedProjects = this.getTotalResumeProjects(data);
          this.totalProjects = formattedProjects.total;
          this.projectsFile = formattedProjects.projects;
          this.isLoading = false;
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    getSummary() {
      PlanificationService.getChartSummary(this.enterpriseId)
        .then((data) => {
          if (data?.series[0]?.data) {
            this.chartSummary.series = data.series[0].data;
          }
          this.summaryPlanification = data.data;
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    getBudgetData() {
      BudgetService.getSummaryBudget(this.enterpriseId)
        .then((data) => {
          if (data?.resume) {
            this.budgetData.currentExpense = data.resume[0].value;
            this.budgetData.totalBudget = data.resume[4].value;
            this.budgetData.deviation = data.resume[2].name;
            this.chartExpense.series = data.resume[0].data;
          }
        })
        .catch((err) => console.dir(err));

      BudgetService.getTableCostCenter(this.enterpriseId)
        .then((data) => {
          this.budgetData.monetaryUnit = data.monetaryUnit;
        })
        .catch((err) => console.dir(err));
    },
    getProductivityData() {
      productivityService
        .getChartInvestment(this.enterpriseId)
        .then((data) => {
          if (data?.investment[0]?.data?.length > 0) {
            this.chartInvestment.series = data.investment;
            this.$refs.chartInvestment.updateOptions({
              xaxis: {
                categories: data.investment[0].categories,
              },
            });
          }
        })
        .catch((err) => console.dir(err));

      productivityService
        .getChartScalability(this.enterpriseId)
        .then((data) => {
          if (data?.service && data?.process) {
            this.chartService.series = data.service.series;
            this.chartProcess.series = data.process.series;
          }
        })
        .catch((err) => console.dir(err));
    },
    getEngagementData() {
      EngagementService.getTechnologyAdoption(this.enterpriseId)
        .then((data) => {
          if (data?.series) {
            this.chartTechnologyAdoption.series = data.series;
          }
        })
        .catch((err) => console.dir(err));
      EngagementService.getCircleConversionRate(this.enterpriseId)
        .then((data) => {
          if (data?.series) {
            this.chartCircleConversionRate.series = data.series;
          }
        })
        .catch((err) => console.dir(err));
    },
    getPerformanceData() {
      PerformanceService.getChartYield(this.enterpriseId)
        .then((data) => {
          if (data?.series) {
            this.chartYield.series = data.series;
          }
        })
        .catch((err) => console.dir(err));
      PerformanceService.getChartTechAndSystemTraining(this.enterpriseId)
        .then((data) => {
          if (data?.skills?.series) {
            this.chartDigitalSkillsImprovement.series = data.skills.series;
          }
        })
        .catch((err) => console.dir(err));
    },
    getRiskData() {
      RiskService.getTopRisk(this.enterpriseId)
        .then((data) => {
          this.riskData = data.mainRisks;
          let lowRisk = [];
          let mediumRisk = [];
          let highRisk = [];
          this.riskData.forEach((r) => {
            console.log("cat: ", r);
            if (r.priority >= 15) {
              highRisk.push("hr");
              this.riskDimension = r.category;
            } else if (r.priority > 6 && r.priority < 15) {
              mediumRisk.push("mr");
              this.riskDimension = r.category;
            } else if (r.priority <= 6) {
              lowRisk.push("lr");
              this.riskDimension = r.category;
            }
          });
          if (highRisk.length != 0) {
            this.riskResult = "hr";
          } else if (highRisk.length == 0 && mediumRisk.length != 0) {
            this.riskResult = "mr";
          } else if (highRisk.length == 0 && mediumRisk.length == 0) {
            this.riskResult = "lr";
          }
        })
        .catch((err) => console.dir(err));
    },
    getProfitability() {
      ProfitabilityService.getChartAnualProfit(this.enterpriseId)
        .then((data) => {
          if (data?.process?.data) {
            this.chartDigitizationProcess.series = data.process.data;
            this.chartOpimization.series = data.opmization.data;
            this.chartDigitizationProcess.data = true;
            this.chartOpimization.data = true;
          } else {
            this.chartDigitizationProcess.data = false;
            this.chartOpimization.data = false;
          }
        })
        .catch((err) => console.dir(err));
    },
    getEfficiencyData() {
      EfficiencyService.getChartDigitalTools(this.enterpriseId)
        .then((data) => {
          if (data?.cost) {
            this.chartCostReduction.series = data.cost;
            this.$refs.chartCostReduction.updateOptions({
              xaxis: {
                categories: data.cost[0].dates,
              },
              yaxis: [{ title: { text: this.budgetData.monetaryUnit } }],
            });
          }
        })
        .catch((err) => console.dir(err));
      EfficiencyService.getChartDigitalChannels(this.enterpriseId)
        .then((data) => {
          if (data?.sale) {
            this.chartDigitalSale.series = data.sale;
            this.$refs.chartDigitalSale.updateOptions({
              xaxis: {
                categories: data.sale[0].dates,
              },
            });
          }
        })
        .catch((err) => console.dir(err));
    },
    getKpisData() {
      KpiBusinessService.getFirstLine(this.enterpriseId)
        .then((data) => {
          if (data?.series) {
            this.firstLine.series = data.series[0].data;
            this.firstLine.options.labels = data.labels;
          }
        })
        .catch((err) => console.dir(err));

      KpiBusinessService.getSummaryKPINegocio(this.enterpriseId)
        .then((data) => {
          if (data?.resumen) {
            this.npsValue = data.resumen[0].value;
          }
        })
        .catch((err) => console.dir(err));
    },
    getOkrData() {
      ObjectivesService.getChartDashboardSummary(this.enterpriseId)
        .then((data) => {
          this.chartOkr1.series = data.okr1.series;
          this.chartOkr2.series = data.okr2.series;
          this.chartOkr3.series = data.okr3.series;

          let total1 = 0;
          data.okr1.series.forEach((t) => {
            total1 += t;
          });
          this.chartOkr1.total = total1;
          let total2 = 0;
          data.okr2.series.forEach((t) => {
            total2 += t;
          });
          this.chartOkr2.total = total2;
          let total3 = 0;
          data.okr3.series.forEach((t) => {
            total3 += t;
          });
          this.chartOkr3.total = total3;

          this.okrTitle.title1 = data.okr1.description;
          this.okrTitle.title2 = data.okr2.description;
          this.okrTitle.title3 = data.okr3.description;
          this.$refs.chartOkr1.updateOptions({
            labels: data.okr1.metaInformation,
          });
          this.$refs.chartOkr2.updateOptions({
            labels: data.okr2.metaInformation,
          });
          this.$refs.chartOkr3.updateOptions({
            labels: data.okr3.metaInformation,
          });
          this.date.start = data.startDate;
          this.date.end = data.endDate;
        })
        .catch((err) => console.dir(err));
    },
    getAgilityData() {
      AgilityService.getAgilityDash(this.enterpriseId)
        .then((data) => {
          if (data?.series) {
            this.chartAgility.series = data.series;
          }
          // this.$refs.chartAgility.updateOptions({
          //   xaxis: {
          //     categories: data.series[0].categories,
          //   },
          // });
        })
        .catch((err) => console.dir(err));
    },
    scrollHorizontal() {
      if (this.$refs.table.scrollLeft > 0) {
        this.showInfo = false;
      }
      if (this.$refs.table.scrollLeft == 0) {
        this.showInfo = true;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.div-container {
  font-family: Josefin Sans, Arial, sans-serif;
}
.alert-pmo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  color: #16c62e;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Verde Manzana / Verde Manzana 4 */

  background: #c5f1cb;
  border-radius: 4px;
}
.chip-pmo {
  background: #dcc5f4;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: fit-content;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  /* Tway / Violeta */

  color: #7319d5;
}
.text-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box-container {
  display: flex;
  align-items: stretch;
}
.height-fix {
  height: 250px;
}
.fill-height {
  height: 100%;
}
.box-content {
  height: 90%;
  display: flex;
  flex-direction: row;
  align-content: space-between;
}
.box-container > .inside-box {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
  height: inherit;
}
.box-container > .inside-box2 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
}

.inside-box > div {
  padding-top: 0px;
  margin-top: 0px;
}
.classification-color {
  border-radius: 70px;
  width: 15px;
  height: 15px;
  border: 1px;
}

.tway-header-1,
.grid-container {
  border-radius: 20px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.ficha-text {
  width: fit-content;
}
.ficha-text div {
  width: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 0px !important;
}
.ficha-text div span:first-child {
  width: 215px;
}

.text-veritical-align {
  position: relative;
  top: 45px;
  text-align: center;
}

.sindatos-info {
  padding: 40px 20px;
  text-align: center;
  padding-top: 40px !important;
}
.modalSystem {
  border-radius: 0px !important;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light,
.edit_row .v-text-field__details {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.v-btn.primary {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}
.modalSystem .edit_row fieldset {
  border-color: #fff !important;
  background-color: #f2f2f2;
}
.modalSystem .v-data-table__wrapper {
  max-height: 408px;
  overflow: auto;
}
.modalSystem .edit_row.is-visible fieldset {
  border-color: transparent !important;
  background-color: transparent;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}

.table-responsive {
  position: relative;
}
.table-responsive::-webkit-scrollbar {
  height: 10px;
}

.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #333333;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #333333;
  outline: 1px solid #333333;
}

.table-projects {
  margin-top: 20px !important;
  //width: 15000px;
  font-size: 11px;
  text-align: center;
  border: 0.5px solid rgb(185, 185, 185);
  border-collapse: collapse;
  .first-th {
    border: 0.5px solid rgb(185, 185, 185);
    padding: 8px;
  }
  .first-th:last-child {
    border: 0.5px solid rgb(185, 185, 185);
    border-bottom: 0px;
  }
  .second-th {
    border: 0.5px solid rgb(185, 185, 185);
    padding: 6px;
  }
  .left-border-td {
    border-left: 0.5px solid rgb(185, 185, 185);
  }
  .right-border-td {
    border-right: 0.5px solid rgb(185, 185, 185);
  }
  .left-border-th {
    border-left: 0.5px solid rgb(185, 185, 185);
  }
  .right-border-th {
    border-right: 0.5px solid rgb(185, 185, 185);
  }
  td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    padding: 8px;
    min-width: 80px;
  }
  .inter-table-th {
    text-align: right;
    padding-right: 20px;
  }
}

@media screen and (max-width: 1635px) {
  .fix-text {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1636px) {
  .text-veritical-align {
    position: relative;
    top: 70px;
  }
}
</style>
